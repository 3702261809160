<template>
  <div>
    <general-table
      ref="feesProfileTable"
      api-url="fees-profiles"
      type="modal"
      :columns="columns"
      :selectable="false"
      edit-component="edit-category"
      :delete-content="false"
      :view-content="false"
      :search-box="false"
      guard="friends"
    >
      <template #cell(user_fees)="data">
        {{ data.item.user_fees }}
      </template>
    </general-table>
    <div>
      <b-modal
        id="feesProfile"
        ref="genmodal"
        hide-footer
        centered
        size="md"
        no-close-on-backdrop
        :title="$store.state.generalIds.id ? 'Update Fees Profile' : 'Add Fees Profile'"
      >
        <add-edit-fees-profile />
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import AddEditFeesProfile from './control-feesProfile/AddEditFeesProfile.vue'

export default {
  components: { GeneralTable, AddEditFeesProfile },
  data() {
    return {
      columns: [
        { key: 'id', sortable: false },
        { key: 'service_name', label: 'Service Name' },
        { key: 'channel', label: 'Channel' },
        { key: 'service_code', label: 'Service Code' },
        { key: 'service_provider_code', label: 'Service Provider Code' },
        { key: 'value_from', label: 'Value From' },
        { key: 'value_to', label: 'Value To' },
        { key: 'channel_fees', label: 'Channel Fees' },
        { key: 'zerocash_fees', label: 'Zerocash Fees' },
        { key: 'user_fees', label: 'User Fees' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
