<template>
  <div>
    <validation-observer
      ref="feesProfileForm"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="ValueFrom"
            rules="regex:^([0-9]*[.])?[0-9]+$"
          >
            <b-form-group
              label="Value From"
              label-for="ValueFrom"
            >
              <b-form-input
                id="ValueFrom"
                v-model.number="feesProfileForm.value_from"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Value From"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="ValueTo"
            rules="regex:^([0-9]*[.])?[0-9]+$"
          >
            <b-form-group
              label="Value To"
              label-for="ValueTo"
            >
              <b-form-input
                id="ValueTo"
                v-model.number="feesProfileForm.value_to"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Value To"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="ChannelFees"
            rules="regex:^([0-9]*[.])?[0-9]+$"
          >
            <b-form-group
              label="Channel Fees"
              label-for="ChannelFees"
            >
              <b-form-input
                id="ChannelFees"
                v-model.number="feesProfileForm.channel_fees"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Channel Fees"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="ZerocashFees"
            rules="regex:^([0-9]*[.])?[0-9]+$"
          >
            <b-form-group
              label="Zerocash Fees"
              label-for="ZerocashFees"
            >
              <b-form-input
                id="ZerocashFees"
                v-model.number="feesProfileForm.zerocash_fees"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Zerocash Fees"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="CheckFees"
          >
            <b-form-group
              label="Calculate user fees automatically"
              label-for="CheckFees"
            >
              <b-form-checkbox
                id="CheckFees"
                v-model="feesProfileForm.check_fees"
                :value="1"
                :unchecked-value="0"
                class="custom-control-secondary mt-1"
                name="check-button"
                :state="getValidationState(validationContext)"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="!Boolean(feesProfileForm.check_fees)"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="UserFees"
            rules="regex:^([0-9]*[.])?[0-9]+$"
          >
            <b-form-group
              label="User Fees"
              label-for="UserFees"
            >
              <b-form-input
                id="UserFees"
                v-model.number="feesProfileForm.user_fees"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="User Fees"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addFeesProfile"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import formValidation from '@core/comp-functions/forms/form-validation'
import { regex } from '@validations'
import feesProfile from '../feesProfile'

export default {
  data() {
    return {
      regex,
      feesProfileForm: {},
      id: this.$store.state.generalIds.id,
      loader: false,
      validationErrors: {},
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()

    const { feesProfileForm } = feesProfile()

    return {
      getValidationState,
      feesProfileForm,
    }
  },
  mounted() {
    this.viewFeesProfile()
  },
  methods: {
    closeModal() {
      this.feesProfileForm = {
        value_from: '',
        value_to: '',
        channel_fees: '',
        zerocash_fees: '',
        check_fees: 1,
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('feesProfile')
    },
    addFeesProfile() {
      if (this.id) {
        this.loader = true
        axios.put(`fees-profiles/${this.id}`, this.feesProfileForm).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.feesProfileTable.getAllData()
            this.closeModal()
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        axios.post('fees-profiles', this.feesProfileForm).then(res => {
          this.$parent.$parent.$parent.$refs.feesProfileTable.getAllData()
          if (res.status === 200 || res.status === 201) {
            this.closeModal()
            this.$toasted.show('Added Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          this.errors = error?.response?.data?.errors
        }).finally(() => {
          this.loader = false
        })
      }
    },
    viewFeesProfile() {
      if (this.id) {
        this.loader = true
        axios.get(`fees-profiles/${this.id}`).then(res => {
          if (res.status === 200) {
            this.feesProfileForm = res.data?.data
            this.feesProfileForm.check_fees = 1
          }
        }).catch(error => {
          this.errors = error?.response?.data?.errors
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
