import { ref } from '@vue/composition-api'

export default function feesProfile() {
  const feesProfileForm = ref({
    value_from: '',
    value_to: '',
    channel_fees: '',
    zerocash_fees: '',
    check_fees: 1,
  })

  const viewData = ref({})

  return {
    feesProfileForm,
    viewData,
  }
}
